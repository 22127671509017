;(function($){
	'use strict';
	var DH = {
		init: function(){
			
			//User Login and register account.
			this.userInit();
		},
		userInit: function(){
			$(document).on('click','[data-rel=buyCoinModal]',function(e){
				if(isEmployer == 1){
					e.stopPropagation();
					e.preventDefault();
					var target = this;
					$(target).attr('disabled', 'disabled');
					BootstrapDialog.show({
				        title: messageBuyCoin,
				        message: messageBuyCoinQuestion,
				        buttons: [{
				            label: 'Đăng ký',
				            cssClass: 'btn-primary',
				            action: function(dialogItself){
				                jQuery.ajax({
							        'type' : 'POST',
							        headers: {
							            'X-XSRF-Token': _token
							        },
							        'dataType': 'json',
							        'url' : basePath + '/nha-tuyen-dung/dang-ky-mua-vncoin',
							        data: {
							            id : jQuery(target).data('id'),
							            '_token': _token
							        },
							        complete: function(){
							            jQuery(target).removeAttr('disabled');
							        },
							        success: function(data){
							            if(data['ok']){
							                BootstrapDialog.show({
							                    type: BootstrapDialog.TYPE_INFO,
							                    title: messageNotify,
							                    message: data['smessage']
							                });
							            }
							            else{
							                BootstrapDialog.show({
							                    type: BootstrapDialog.TYPE_DANGER,
							                    title: messageNotify,
							                    message: data['serror']
							                });
							            }
							            dialogItself.close();
							        },
							        error: function(err){
							            console.log(err);
							        }
							    });
				            }
				        }, {
				            label: 'Huỷ',
				            action: function(dialogItself){
				            	$(target).removeAttr('disabled');
				                dialogItself.close();
				            }
				        }]
				    });
				    return false;
				}
				else{
					BootstrapDialog.show({
	                    type: BootstrapDialog.TYPE_DANGER,
	                    title: messageNotify,
	                    message: messageEmployer,
	                    onhide: function(dialogRef){
	                        if($('#employerloginModal').length){
								$('#employerloginModal').modal('show');
							}
	                    }
	                });
					return false;
				}
			});

			$(document).on('click','[data-rel=registerPackageModal]',function(e){
				e.stopPropagation();
				e.preventDefault();
				if($('#registerPackageModal').length){
					$('#registerPackageModal').modal('show');
				}
			});

			$(document).on('click','[data-rel=registerModal]',function(e){
				e.stopPropagation();
				e.preventDefault();
				if($('#userloginModal').length){
					$('#userloginModal').modal('hide');
				}
				if($('#userlostpasswordModal').length){
					$('#userlostpasswordModal').modal('hide');
				}
				if($('#userregisterModal').length){
					$('#userregisterModal').modal('show');
				}
			});

         	
			$(document).on('click','[data-rel=registeremployerModal]',function(e){
				e.stopPropagation();
				e.preventDefault();
				if($('#employerloginModal').length){
					$('#employerloginModal').modal('hide');
				}
				if($('#userlostpasswordModal').length){
					$('#userlostpasswordModal').modal('hide');
				}
				if($('#registeremployerModal').length){
					$('.jform-error').html('');
					$('input[name="logo_company"]').replaceWith($('input[name="logo_company"]').val('').clone(true));
					$('#registeremployerModal').modal('show');
				}
			});  

			$(document).on('click','[data-rel=employerloginModal]',function(e){
				e.stopPropagation();
				e.preventDefault();
				if($('#registeremployerModal').length){
					$('#registeremployerModal').modal('hide');
				}
				if($('#userlostpasswordModal').length){
					$('#userlostpasswordModal').modal('hide');
				}
				if($('#employerloginModal').length){
					$('#employerloginModal').modal('show');
				}
			});
        
        	$(document).on('click','[data-rel=registersuicideModal]',function(e){
				e.stopPropagation();
				e.preventDefault();
				
				if($('#registersuicideModal').length){
					$('#registersuicideModal').modal('show');
				}
			});
			$(document).on('click','[data-rel=loginModal]',function(e){
				e.stopPropagation();
				e.preventDefault();
				if($('#userregisterModal').length){
					$('#userregisterModal').modal('hide');
				}
				if($('#userlostpasswordModal').length){
					$('#userlostpasswordModal').modal('hide');
				}
				if($('#userloginModal').length){
					$('#userloginModal').modal('show');
				}
			});


			$(document).on('click','[data-rel=jobnotifyModal]',function(e){
				e.stopPropagation();
				e.preventDefault();
				if($('#jobnotifyModal').length){
					$('.jform-error').html('');
					var target = $(this);
					var locations = target.data('locations');
					if(typeof(locations) != "undefined"){
						var vals = [];
						jQuery.each(locations, function(i, val){
							vals.push(val.id);
						});
						jQuery('#jobnotifyModal').find('#job_locations').val(vals).trigger("change");
					}

					var categories = target.data('categories');
					if(typeof(categories) != "undefined"){
						var vals = [];
						jQuery.each(categories, function(i, val){
							vals.push(val.id);
						});
						jQuery('#jobnotifyModal').find('#job_categories').val(vals).trigger("change");
					}
					jQuery('#jobnotifyModal').find('#career_level').val(target.data('level'));
					$('#jobnotifyModal').modal('show');
				}
			});
			$(document).on('click','[data-rel=userlostpasswordModal]',function(e){
				e.stopPropagation();
				e.preventDefault();
				if($('#userregisterModal').length){
					$('#userregisterModal').modal('hide');
				}
				if($('#userloginModal').length){
					$('#userloginModal').modal('hide');
				}
				if($('#registeremployerModal').length){
					$('#registeremployerModal').modal('hide');
				}
				if($('#employerloginModal').length){
					$('#employerloginModal').modal('hide');
				}
				if($('#userlostpasswordModal').length){
					$('#userlostpasswordModal').modal('show');
				}
			});
			
			$(document).on('click','[data-rel=companyRatingModal]',function(e){
				e.stopPropagation();
				e.preventDefault();

				if($('#companyRatingModal').length){
					var target = $(this);
					console.log(target);
					var receiver = target.data('company');
					var sender = target.data('sender');
					var startSelected = $('input[name=star]:checked').val();
					$('#companyRatingModal').find('input[name="id_employer"]').val(receiver);
					$('#companyRatingModal').find('input[name="id_sender"]').val(sender);
					$('#companyRatingModal').modal('show');
				}
			});
			$(document).on('click','[data-rel=sendMessageModal]',function(e){
				e.stopPropagation();
				e.preventDefault();
				if($('#sendMessageModal').length){
					$('#message').val('');
					$('#message_title').val('');
					$('input[name="id_employer"]').val('');

					$('.jform-error').html('');
					var target = $(this);
					var receiver = target.data('receiver');
					if(receiver != null && receiver != ""){
						$('#sendMessageModal').find('.nhatuyendung').hide();
						$('#sendMessageModal').find('input[name="id_employer"]').show();
						$('#sendMessageModal').find('input[name="id_employer"]').val(receiver);
						$('input[name=star]:checked').val();
						var mail = target.data('mail');
						if(mail != null && mail != ""){
							$('#sendMessageCandidateModal').find('input[name="mail"]').val(mail);
						}
					}
					else{
						$('#sendMessageModal').find('.nhatuyendung').show();
						$('#sendMessageModal').find('input[name="id_employer"]').hide();
						jQuery("#mail_receiver").ajaxChosen({
							headers: {
					            'X-XSRF-Token': _token
					        },
						    type: 'GET',
						    minTermLength: 2,
						    url: basePath + '/ajax-nhatuyendung',
						    dataType: 'json'
						}, function (data) {
						    var results = [];
						    jQuery.each(data, function (i, val) {
						        results.push({ value: val.id, text: val.email });
						    });
						    return results;
						});
					}
					if(target.data('sender') != "") $('#sendMessageModal').find('#message_title').val(replayMessage + target.data('receiver_name'));

					$('#sendMessageModal').modal('show');
				}
			});
			$(document).on('click','[data-rel=sendMessageCandidateModal]',function(e){
				e.stopPropagation();
				e.preventDefault();
				if($('#sendMessageCandidateModal').length){
					$('#message').val('');
					$('#message_title').val('');
					$('input[name="id_user"]').val('');
					$('.jform-error').html('');

					var target = $(this);
					var receiver = target.data('receiver');
					if(receiver != null && receiver != ""){
						$('#sendMessageCandidateModal').find('.ungvien').hide();
						$('#sendMessageCandidateModal').find('input[name="id_user"]').show();
						$('#sendMessageCandidateModal').find('input[name="id_user"]').val(receiver);
						var mail = target.data('mail');
						if(mail != null && mail != ""){
							$('#sendMessageCandidateModal').find('input[name="mail"]').val(mail);
						}
					}
					else{
						$('#sendMessageCandidateModal').find('.ungvien').show();
						$('#sendMessageCandidateModal').find('input[name="id_user"]').hide();
						jQuery("#mail_receiver").ajaxChosen({
							headers: {
					            'X-XSRF-Token': _token
					        },
						    type: 'GET',
						    minTermLength: 2,
						    url: basePath + '/ajax-ungvien',
						    dataType: 'json'
						}, function (data) {
						    var results = [];
						    jQuery.each(data, function (i, val) {
						        results.push({ value: val.id, text: val.email });
						    });
						    return results;
						});
					}
					if(target.data('sender') != "") $('#sendMessageCandidateModal').find('#message_title').val(replayMessage + target.data('receiver_name'));
					$('#sendMessageCandidateModal').modal('show');
				}
			});

			$(document).on('click','[data-rel=applyJobModal]',function(e){
				e.stopPropagation();
				e.preventDefault();
				
				if($(this).hasClass('opacity6')){
					return false;
				}
				if(isCandidate == 0){
					if($('#modal-compare-recruitments').length){
						$('#modal-compare-recruitments').modal('hide');
					}
					BootstrapDialog.show({
	                    type: BootstrapDialog.TYPE_DANGER,
	                    title: messageNotify,
	                    message: messageCandidate,
	                    onhide: function(dialogRef){
	                        if($('#userloginModal').length){
								$('#userloginModal').modal('show');
							}
	                    }
	                });
					return false;
				}

				if($('#applyJobModal').length){
					$('.jform-error').html('');
					$('input[name="application_attachment"]').replaceWith($('input[name="application_attachment"]').val('').clone(true));
					
					$('#applyJobModal').find('input[name="job_id"]').val('');
					$('#application_message').val('');
					$('#applyJobModalLabel > strong').html();
					$('#profile-lan > span').html();

					var target = $(this);
					$('#applyJobModal').find('input[name="job_id"]').val(target.data('id'));
					$('#applyJobModal').find('#applyJobModalLabel > strong').html(target.data('name'));
					$('#applyJobModal').find('#profile-lan > span').html(target.data('lan'));

					$('#applyJobModal').modal('show');
				}
			});
		},
	};

	function initAffix() {
        $('#affix-box').affix({
            offset: {
                top: function () {
                    return (this.top = $('#affix-box').offset().top)
                },
                bottom: function () {
                    return (this.bottom = $('.main-footer').outerHeight(true) + $('#map-area').outerHeight(true) + 120)
                }
            }
        });
    }
    function stickysidebar()
    {
        $('.content, .sidebar').theiaStickySidebar({
            additionalMarginTop: 90,
            additionalMarginBottom: 30
        });
    }
$(document).ready(function(){
		DH.init();
		initAffix();
		stickysidebar();

		$('#expand-search').click(function(e){
			e.preventDefault();
			if($(this).attr('data-show') == 1){
				$('.advanced-search').hide();
				$(this).attr('data-show', 0);
			}
			else{
				$('.advanced-search').show();	
				$(this).attr('data-show', 1);
			}
		});
		if($("#keyword").length > 0){
			$("#keyword").autocomplete({
				'serviceUrl': "/get-tags",
				'dataType'  : 'json',
				'paramName' : 'q',
				'deferRequestBy': 100,
				transformResult: function(response) {
			        return {
			            suggestions: $.map(response.items, function(dataItem) {
			                return { value: dataItem.text, data: dataItem.id };
			            })
			        };
			    }
			});
		}
		
		// $('.scrollbar').enscroll({
		// 	verticalTrackClass: 'track1',
		// 	verticalHandleClass: 'handle1',
		// 	drawScrollButtons: true,
		// 	scrollUpButtonClass: 'scroll-up1',
		// 	scrollDownButtonClass: 'scroll-down1'
		// });

		// Cookies.remove('popupTimer');
		if(isMobile == ""){
			var popupTimer = $.cookie('popupTimer');
			if(isLogin == 0 && (typeof popupTimer == 'undefined') ){
				$('#popupModal').modal('show');
				$.cookie('popupTimer', 1, { expires: 1 });
			}
		}
	});
})(jQuery);
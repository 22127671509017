String.prototype.replaceAll = function(search, replacement) {
    var target = this;
    return target.split(search).join(replacement);
};

function parseURL(){
    var $_GET = {};
    if(document.location.toString().indexOf('?') !== -1) {
        var query = document.location
                       .toString()
                       // get the query string
                       .replace(/^.*?\?/, '')
                       // and remove any existing hash string (thanks, @vrijdenker)
                       .replace(/#.*$/, '')
                       .split('&');

        for(var i=0, l=query.length; i<l; i++) {
           var aux = decodeURIComponent(query[i]).split('=');
           $_GET[aux[0]] = aux[1].replaceAll("+", " ");
        }
    }
    return $_GET;
}
function openWindow( theURL, winName, features) { //v2.0
    window.open(theURL,winName,features);
}
function print_preview(p_url,p_style){
    openWindow(p_url,'',p_style);
}

function comfirmUrl(target, e, content){
    e.preventDefault();
    BootstrapDialog.show({
        message: content,
        buttons: [{
            label: 'OK',
            cssClass: 'btn-primary',
            action: function(){
                window.location = jQuery(target).attr('href');
            }
        }, {
            label: 'Cancel',
            action: function(dialogItself){
                dialogItself.close();
            }
        }]
    });
}

function viewInfoResume(target, e){
    e.preventDefault();
    jQuery(target).attr('disabled', 'disabled');
    jQuery.ajax({
        'type' : 'POST',
        headers: {
            'X-XSRF-Token': _token
        },
        'dataType': 'json',
        'url' : basePath + '/nha-tuyen-dung/view-resume-info',
        data: {
            id : jQuery(target).data('id'),
            '_token': _token
        },
        complete: function(){
            jQuery(target).removeAttr('disabled');
        },
        success: function(data){
            if(data['ok']){
                jQuery('.fa-facebook').attr('href', data['result']['facebook']);
                jQuery('.fa-twitter').attr('href', data['result']['twitter']);
                jQuery('.fa-google-plus').attr('href', data['result']['googleplus']);
                jQuery('.email-resume > span').html(data['result']['email']);
                jQuery('.phone-resume > span').html(data['result']['phone']);
                if(data['result']['cv'] != ''){
                    jQuery('#view_file_cv').html('<a href="' + decodeURIComponent(data['result']['cv']) + '" target="_blank"><button class="btn btn-primary mb-1" style="color: white;">' + downloadCVMessage + '</button></a><iframe src="http://docs.google.com/gview?embedded=true&url=' + data['result']['cv'] + '" width="100%" height="500px" frameborder="0"></iframe>');
                }
                jQuery('.info-resume').show();
                jQuery(target).hide();
            }
            else{
                BootstrapDialog.show({
                    type: BootstrapDialog.TYPE_DANGER,
                    title: messageNotify,
                    message: data['serror']
                });
            }
        },
        error: function(err){
            console.log(err);
        }
    });
}
function newsletter(target, e){
    jQuery('.jform-error').html('');
    e.preventDefault();
    jQuery.ajax({
        'type' : 'POST',
        headers: {
            'X-XSRF-Token': _token
        },
        'dataType': 'json',
        'url' : jQuery(target).attr('action'),
        data: jQuery(target).serialize(),
        beforeSend: function(){
            jQuery('.coverlay').css({'display' : 'table'});
        },
        complete: function(){
            jQuery('.coverlay').hide();
        },
        success: function(data){
            if(data['ok']){
                BootstrapDialog.show({
                    type: BootstrapDialog.TYPE_INFO,
                    title: messageNotify,
                    message: data['smessage']
                });
            }
            else{
                if(data['code'] == errorValidate){
                    var errors = jQuery.parseJSON(data['params']['validator']);
                    jQuery.each(errors, function(i, val){
                        if(typeof(errors[i]) != "undefined") jQuery('.jform-error.error_' + i).html( val[0] );
                    });
                }
                else{
                    BootstrapDialog.show({
                        type: BootstrapDialog.TYPE_DANGER,
                        title: messageNotify,
                        message: data['serror']
                    });
                }
            }
        },
        error: function(err){
            console.log(err);
        }
    });
    return false;
}

function forgetPass(target, e){
    jQuery('.jform-error').html('');
    e.preventDefault();
    jQuery.ajax({
        'type' : 'POST',
        headers: {
            'X-XSRF-Token': _token
        },
        'dataType': 'json',
        'url' : jQuery(target).attr('action'),
        data: jQuery(target).serialize(),
        beforeSend: function(){
            jQuery('.coverlay').css({'display' : 'table'});
        },
        complete: function(){
            jQuery('.coverlay').hide();
        },
        success: function(data){
            if(data['ok']){
                BootstrapDialog.show({
                    type: BootstrapDialog.TYPE_INFO,
                    title: messageNotify,
                    message: data['smessage']
                });
                jQuery('#userlostpasswordModal').modal('hide');
            }
            else{
                if(data['code'] == errorValidate){
                    var errors = jQuery.parseJSON(data['params']['validator']);
                    jQuery.each(errors, function(i, val){
                        if(typeof(errors[i]) != "undefined") jQuery('.jform-error.error_' + i).html( val[0] );
                    });
                }
                else if(data['code'] == errorAuth){
                    window.location = basePath + '/auth/member';
                }
                else{
                    BootstrapDialog.show({
                        type: BootstrapDialog.TYPE_DANGER,
                        title: messageNotify,
                        message: data['serror']
                    });
                }
            }
        },
        error: function(err){
            console.log(err);
        }
    });
    return false;
}

function saveResume(target, e){
    e.preventDefault();
    jQuery('.jform-error').html('');
    jQuery.ajax({
        'type' : 'POST',
        headers: {
            'X-XSRF-Token': _token
        },
        'dataType': 'json',
        'url' : basePath + '/nha-tuyen-dung/save-resume',
        data: {
            'resume_id' : jQuery(target).data('id'),
            '_token' : _token
        },
        success: function(data){
            if(data['ok']){
                BootstrapDialog.show({
                    type: BootstrapDialog.TYPE_INFO,
                    title: messageNotify,
                    message: data['smessage']
                });
            }
            else{
                if(data['code'] == errorAuth){
                    window.location = basePath + '/auth/member';
                }
                else{
                    BootstrapDialog.show({
                        type: BootstrapDialog.TYPE_DANGER,
                        title: messageNotify,
                        message: data['serror']
                    });
                }
            }
        },
        error: function(err){
            console.log(err);
        }
    });
    return false;
}

function saveJob(target, e){
    e.preventDefault();
    jQuery('.jform-error').html('');
    jQuery.ajax({
        'type' : 'POST',
        headers: {
            'X-XSRF-Token': _token
        },
        'dataType': 'json',
        'url' : basePath + '/ung-vien/savejob',
        data: {
            'job_id' : jQuery(target).data('id'),
            '_token' : _token
        },
        success: function(data){
            if(data['ok']){
                BootstrapDialog.show({
                    type: BootstrapDialog.TYPE_INFO,
                    title: messageNotify,
                    message: data['smessage']
                });
            }
            else{
                if(data['code'] == errorAuth){
                    BootstrapDialog.show({
                        type: BootstrapDialog.TYPE_DANGER,
                        title: messageNotify,
                        message: messageCandidate,
                        onhide: function(dialogRef){
                            if(jQuery('#userloginModal').length){
                                jQuery('#userloginModal').modal('show');
                            }
                        }
                    });
                }
                else{
                    BootstrapDialog.show({
                        type: BootstrapDialog.TYPE_DANGER,
                        title: messageNotify,
                        message: data['serror']
                    });
                }
            }
        },
        error: function(err){
            console.log(err);
        }
    });
    return false;
}

function fileUpload(form, e, div_id, modal_id) {
    jQuery('iframe[name="upload_iframe"]').remove();
    jQuery('.coverlay').css({'display' : 'table'});
    e.preventDefault();
    // Create the iframe...
    var iframe = document.createElement("iframe");
    iframe.setAttribute("id", "upload_iframe");
    iframe.setAttribute("name", "upload_iframe");
    iframe.setAttribute("width", "0");
    iframe.setAttribute("height", "0");
    iframe.setAttribute("border", "0");
    iframe.setAttribute("style", "width: 0; height: 0; border: none;");
 
    // Add to document...
    form.parentNode.appendChild(iframe);
    window.frames['upload_iframe'].name = "upload_iframe";
   
    iframeId = document.getElementById("upload_iframe");
 
    // Add event...
    var eventHandler = function () {
 
            if (iframeId.detachEvent) iframeId.detachEvent("onload", eventHandler);
            else iframeId.removeEventListener("load", eventHandler, false);
 
            // Message from server...
            if (iframeId.contentDocument) {
                content = iframeId.contentDocument.body.innerHTML;
            } else if (iframeId.contentWindow) {
                content = iframeId.contentWindow.document.body.innerHTML;
            } else if (iframeId.document) {
                content = iframeId.document.body.innerHTML;
            }
            // document.getElementById(div_id).innerHTML = content;
            jQuery('.coverlay').hide();
            // console.log(content);
            content = jQuery.parseJSON(content);
            if(content['ok'] && ( typeof(content['params']) == "undefined" || ( typeof(content['params']) != "undefined" && content['params']['validator'] == undefined ) ) ){
                jQuery('#' + modal_id).modal('hide');

                if(modal_id == "registeremployerModal"){
                    grecaptcha.reset();
                    BootstrapDialog.show({
                        title: messageNotify,
                        message: function(dialog) {
                            var $message = jQuery('<div></div>');
                            var pageToLoad = dialog.getData('pageToLoad');
                            $message.load(pageToLoad);
                            return $message;
                        },
                        data: {
                            'pageToLoad': basePath + '/thong-bao/register-employer-success'
                        },
                        onhide: function(dialogRef){
                            window.location = basePath + '/nha-tuyen-dung/quan-ly-cong-viec';
                        }
                    });
                }
                else{
                    BootstrapDialog.show({
                        type: BootstrapDialog.TYPE_INFO,
                        title: messageNotify,
                        message: content['smessage'],
                        onhide: function(dialogRef){
                            window.location.reload();
                        }
                    });
                }
            }
            else{
                if(typeof(content['params']) != "undefined" && typeof(content['params']['validator']) != "undefined" && content['params']['validator'].length > 0){
                    var error = jQuery.parseJSON(content['params']['validator']);
                    if(typeof(error['is_term']) != "undefined"){
                        content['serror'] = error['is_term'][0];
                    }
                    if(typeof(error['email']) != "undefined"){
                        content['serror'] = error['email'][0];
                    }
                    if(typeof(error['application_attachment']) != "undefined"){
                        content['serror'] = error['application_attachment'][0];
                    }
                }

                BootstrapDialog.show({
                    type: BootstrapDialog.TYPE_DANGER,
                    title: messageNotify,
                    message: content['serror']
                });
            }
 
            // Del the iframe...
            setTimeout('iframeId.parentNode.removeChild(iframeId)', 250);
        }
 
    if (iframeId.addEventListener) iframeId.addEventListener("load", eventHandler, true);
    if (iframeId.attachEvent) iframeId.attachEvent("onload", eventHandler);
    
    

    // Set properties of form...
    form.setAttribute("target", "upload_iframe");
    form.setAttribute("action", form.action);
    form.setAttribute("method", "post");
    form.setAttribute("enctype", "multipart/form-data");
    form.setAttribute("encoding", "multipart/form-data");
    
    // Submit the form...
    form.submit();
    // document.getElementById(div_id).innerHTML = "Uploading...";
}

/**
* Params:
* objId: ids_{{name model}}
* status: status checked "all checkbox button"
*/
function checkAllCheckbox(objId, status){
    var type = objId.split('_')[1];
    var classTarget = ".multi_";
    if(type != "" && type != null){
        classTarget += type;
        jQuery(classTarget).prop('checked', status);
    }
}

/**
* Delete All data
* url: url delete (call destroy method controller)
* data: list id obj need delete
*/
function deleteAll(url, data){
    BootstrapDialog.show({
        message: questionDelete,
        buttons: [{
            label: 'OK',
            cssClass: 'btn-primary',
            action: function(){
                jQuery.ajax({
                    url: url + "/" + data,
                    type: 'DELETE',
                    dataType: 'json',
                    success: function(data){
                        if(data['status'] == 1) window.location.reload();
                        else if(data['status'] == 0) alert("Cannot Delete");
                    },
                    error: function(e){
                        console.log(e);
                    }
                });
            }
        }, {
            label: 'Cancel',
            action: function(dialogItself){
                dialogItself.close();
            }
        }]
    });
}

/**
* Real Data Image upload
* input: fileupload
*/
function readURL(input) {
    if (input.files && input.files[0]) {
        var reader = new FileReader();
        reader.onload = function (e) {
            jQuery('#blah')
                .attr('src', e.target.result)
                // .width(200)
                .height(200);
        };
        reader.readAsDataURL(input.files[0]);
    }
}

function jobNotify(target, e){
    jQuery('.jform-error').html('');
    e.preventDefault();
    jQuery.ajax({
        'type' : 'POST',
        headers: {
            'X-XSRF-Token': _token
        },
        'dataType': 'json',
        'url' : jQuery(target).attr('action'),
        data: jQuery(target).serialize(),
        beforeSend: function(){
            jQuery('.coverlay').css({'display' : 'table'});
        },
        complete: function(){
            jQuery('.coverlay').hide();
        },
        success: function(data){
            if(data['ok']){
                BootstrapDialog.show({
                    type: BootstrapDialog.TYPE_INFO,
                    title: messageNotify,
                    message: data['smessage']
                });
                jQuery('#jobnotifyModal').modal('hide');
            }
            else{
                if(data['code'] == errorValidate){
                    var errors = jQuery.parseJSON(data['params']['validator']);
                    jQuery.each(errors, function(i, val){
                        if(typeof(errors[i]) != "undefined") jQuery('.jform-error.error_' + i).html( val[0] );
                    });
                }
                else if(data['code'] == errorAuth){
                    BootstrapDialog.show({
                        type: BootstrapDialog.TYPE_DANGER,
                        title: messageNotify,
                        message: data['serror'],
                        onhide: function(dialogRef){
                            window.location = basePath + '/auth/member';
                        }
                    });
                }
                else{
                    BootstrapDialog.show({
                        type: BootstrapDialog.TYPE_DANGER,
                        title: messageNotify,
                        message: data['serror']
                    });
                }
            }
        },
        error: function(err){
            console.log(err);
        }
    });
    return false;
}

function sendMessage(target, e){
    jQuery('.jform-error').html('');
    e.preventDefault();
    jQuery.ajax({
        'type' : 'POST',
        headers: {
            'X-XSRF-Token': _token
        },
        'dataType': 'json',
        'url' : jQuery(target).attr('action'),
        data: jQuery(target).serialize(),
        beforeSend: function(){
            jQuery('.coverlay').css({'display' : 'table'});
        },
        complete: function(){
            jQuery('.coverlay').hide();
        },
        success: function(data){
            if(data['ok']){
                BootstrapDialog.show({
                    type: BootstrapDialog.TYPE_INFO,
                    title: messageNotify,
                    message: data['smessage']
                });
                if(jQuery('#sendMessageModal').length){
                    jQuery('#sendMessageModal').modal('hide');
                }
                if(jQuery('#sendMessageCandidateModal').length){
                    jQuery('#sendMessageCandidateModal').modal('hide');
                }
            }
            else{
                if(data['code'] == errorValidate){
                    var errors = jQuery.parseJSON(data['params']['validator']);
                    jQuery.each(errors, function(i, val){
                        if(typeof(errors[i]) != "undefined") jQuery('.jform-error.error_' + i).html( val[0] );
                    });
                }
                else{
                    BootstrapDialog.show({
                        type: BootstrapDialog.TYPE_DANGER,
                        title: messageNotify,
                        message: data['serror']
                    });
                }
            }
        },
        error: function(err){
            console.log(err);
        }
    });
    return false;
}
function register(target, e){
    jQuery('.jform-error').html('');
    e.preventDefault();
    jQuery.ajax({
        'type' : 'POST',
        headers: {
            'X-XSRF-Token': _token
        },
        'dataType': 'json',
        'url' : basePath + '/auth/register',
        data: jQuery(target).serialize(),
        beforeSend: function(){
            jQuery('.coverlay').css({'display' : 'table'});
        },
        complete: function(){
            grecaptcha.reset();
            jQuery('.coverlay').hide();
        },
        success: function(data){
            if(data['ok']){
                if(data['type'] == 'admin') window.location = basePath + '/kojca';
                else if(data['type'] == 'candidate'){
                    window.location = basePath + '/ung-vien/ho-so-ca-nhan/-1/0';
                }
                else if(data['type'] == 'employer'){
                    BootstrapDialog.show({
                        message: function(dialog) {
                            var $message = $('<div></div>');
                            var pageToLoad = dialog.getData('pageToLoad');
                            $message.load(pageToLoad);
                            return $message;
                        },
                        data: {
                            'pageToLoad': basePath + '/thong-bao/register-employer-success'
                        },
                        onhide: function(dialogRef){
                            window.location = basePath + '/nha-tuyen-dung/quan-ly-cong-viec';
                        }
                    });
                }
                else window.location.reload();
            }
            else{
                if(data['code'] == errorValidate){
                    var errors = jQuery.parseJSON(data['params']['validator']);
                    jQuery.each(errors, function(i, val){
                        if(typeof(errors[i]) != "undefined") jQuery('.jform-error.error_' + i).html( val[0] );
                    });
                }
                else{
                    BootstrapDialog.show({
                        type: BootstrapDialog.TYPE_DANGER,
                        title: messageNotify,
                        message: data['serror']
                    });
                }
            }
        },
        error: function(err){
            console.log(err);
        }
    });
    return false;
}

function registerSuicide(target, e){
     jQuery('.jform-error').html('');
    e.preventDefault();
    jQuery.ajax({
        'type' : 'POST',
        headers: {
            'X-XSRF-Token': _token
        },
        'dataType': 'json',
        'url' : jQuery(target).attr('action'),
        data: jQuery(target).serialize(),
        beforeSend: function(){
            jQuery('.coverlay').css({'display' : 'table'});
        },
        complete: function(){
            grecaptcha.reset();
            jQuery('.coverlay').hide();
        },
        success: function(data){
            if(data['ok']){
                BootstrapDialog.show({
                    type: BootstrapDialog.TYPE_INFO,
                    title: messageNotify,
                    message: data['smessage']
                });
                if(jQuery('#registersuicideModal').length){
                    jQuery('#registersuicideModal').modal('hide');
                }
               
            }
            else{
                if(data['code'] == errorValidate){
                    var errors = jQuery.parseJSON(data['params']['validator']);
                    jQuery.each(errors, function(i, val){
                        if(typeof(errors[i]) != "undefined") jQuery('.jform-error.error_' + i).html( val[0] );
                    });
                }
                else{
                    BootstrapDialog.show({
                        type: BootstrapDialog.TYPE_DANGER,
                        title: messageNotify,
                        message: data['serror']
                    });
                }
            }
        },
        error: function(err){
            console.log(err);
        }
    });
    return false;
}

function login(target, e){
    e.preventDefault();
    jQuery.ajax({
        'type' : 'POST',
        headers: {
            'X-XSRF-Token': _token
        },
        'dataType': 'json',
        'url' : basePath + '/auth/login',
        data: jQuery(target).serialize(),
        beforeSend: function(){
            jQuery('.coverlay').css({'display' : 'table'});
        },
        complete: function(){
            jQuery('.coverlay').hide();
        },
        success: function(data){
            if(data['ok']){
                if(data['type'] == 'admin') window.location = basePath + '/kojca';
                // else if(data['type'] == 'candidate') window.location = basePath + '/ung-vien/quan-ly-ho-so-ung-tuyen';
                // else if(data['type'] == 'employer') window.location = basePath + '/nha-tuyen-dung/quan-ly-goi-tuyen-dung';
                else window.location.reload();
            }
            else{
                BootstrapDialog.show({
                    type: BootstrapDialog.TYPE_DANGER,
                    title: messageNotify,
                    message: data['serror']
                });
            }
        },
        error: function(err){
            console.log(err);
        }
    })
}

! function(a) {
    "use strict";
    var b = function() {
            var a = window,
                b = "inner";
            return "innerWidth" in window || (b = "client", a = document.documentElement || document.body), {
                width: a[b + "Width"],
                height: a[b + "Height"]
            }
        },
        d = function() {
            if (a(".navbar").length) {
                var c = a(window),
                    d = a("body"),
                    e = a(".navbar").offset().top,
                    g = 0,
                    h = a(".navbar"),
                    i = a(".navbar").outerHeight(),
                    j = 0;
                d.hasClass("admin-bar") && (j = a("#wpadminbar").outerHeight());
                var k = function() {
                    if (b().width > 992 && h.hasClass("fixed-top")) {
                        var f = "navbar-fixed-top";
                        h.hasClass("shrinkable") && !d.hasClass("one-page-layout") && (f += " navbar-shrink");
                        var k = e + i;
                        if (c.scrollTop() + j > k) {
                            if (h.hasClass("navbar-fixed-top")) return;
                            if (!h.hasClass("navbar-fixed-top")) return g = i, a(".navbar-wrapper").css({
                                "min-height": g + "px"
                            }), h.closest(".noo-header").css({
                                position: "relative"
                            }), void h.addClass(f).css("top", 0 - g).animate({
                                top: j
                            }, 300)
                        } else {
                            if (!h.hasClass("navbar-fixed-top")) return;
                            h.removeClass(f), h.css({
                                top: ""
                            }), a(".navbar-wrapper").css({
                                "min-height": "none"
                            }), h.closest(".noo-header").css({
                                position: ""
                            })
                        }
                    }
                };
                c.on("scroll", k).resize(k), d.hasClass("one-page-layout") && (a('.navbar-scrollspy > .nav > li > a[href^="#"]').on( "click", function(b) {
                    b.preventDefault();
                    var c = a(this).attr("href").replace(/.*(?=#[^\s]+$)/, "");
                    if (c && a(c).length) {
                        var d = Math.max(0, a(c).offset().top);
                        d = Math.max(0, d - (j + a(".navbar").outerHeight()) + 5), a("html, body").animate({
                            scrollTop: d
                        }, {
                            duration: 800,
                            easing: "easeInOutCubic",
                            complete: window.reflow
                        })
                    }
                }), d.scrollspy({
                    target: ".navbar-scrollspy",
                    offset: j + a(".navbar").outerHeight()
                }), a(window).resize(function() {
                    d.scrollspy("refresh")
                }))
            }
            a(".noo-slider-revolution-container .noo-slider-scroll-bottom").on( "click", function(b) {
                b.preventDefault();
                var c = a(".noo-slider-revolution-container").outerHeight();
                a("html, body").animate({
                    scrollTop: c
                }, 900, "easeInOutExpo")
            }), a("body").on("mouseenter", ".masonry-style-elevated .masonry-portfolio.no-gap .masonry-item", function() {
                a(this).closest(".masonry-container").find(".masonry-overlay").show(), a(this).addClass("masonry-item-hover")
            }), a("body").on("mouseleave ", ".masonry-style-elevated .masonry-portfolio.no-gap .masonry-item", function() {
                a(this).closest(".masonry-container").find(".masonry-overlay").hide(), a(this).removeClass("masonry-item-hover")
            }), a(".masonry").each(function() {
                var b = a(this),
                    c = a(this).find(".masonry-container"),
                    d = a(this).find(".masonry-filters a");
                c.isotope({
                    itemSelector: ".masonry-item",
                    transitionDuration: "0.8s",
                    masonry: {
                        gutter: 0
                    }
                }), imagesLoaded(b, function() {
                    c.isotope("layout")
                }), d.on( "click", function(a) {
                    a.stopPropagation(), a.preventDefault();
                    var d = jQuery(this);
                    if (d.hasClass("selected")) return !1;
                    b.find(".masonry-result h3").text(d.text());
                    var e = d.closest("ul");
                    e.find(".selected").removeClass("selected"), d.addClass("selected");
                    var f = {
                            layoutMode: "masonry",
                            transitionDuration: "0.8s",
                            masonry: {
                                gutter: 0
                            }
                        },
                        g = e.attr("data-option-key"),
                        h = d.attr("data-option-value");
                    h = "false" === h ? !1 : h, f[g] = h, c.isotope(f)
                })
            }), a(window).scroll(function() {
                a(this).scrollTop() > 500 ? a(".go-to-top").addClass("on") : a(".go-to-top").removeClass("on")
            }), a("body").on("click", ".go-to-top", function() {
                return a("html, body").animate({
                    scrollTop: 0
                }, 800), !1
            }), a("body").on("click", ".search-button", function() {
                return a(".searchbar").hasClass("hide") && (a(".searchbar").removeClass("hide").addClass("show"), a(".searchbar #s").focus()), !1
            }), a("body").on("mousedown", a.proxy(function(b) {
                var c = a(b.target);
                c.is(".searchbar") || 0 !== c.parents(".searchbar").length || a(".searchbar").removeClass("show").addClass("hide")
            }, this))
        };
    a(document).ready(function() {
        a('[data-toggle="tooltip"]').tooltip(),
        a(".noo-globe-navbar-collapse").on("show.bs.collapse", function() {
            a(".noo-navbar-collapse").hasClass("in") && a(".noo-navbar-collapse").collapse("hide");
            a(".noo-user-navbar-collapse").hasClass("in") && a(".noo-user-navbar-collapse").collapse("hide");
        }), a(".noo-user-navbar-collapse").on("show.bs.collapse", function() {
            a(".noo-globe-navbar-collapse").hasClass("in") && a(".noo-globe-navbar-collapse").collapse("hide");
            a(".noo-navbar-collapse").hasClass("in") && a(".noo-navbar-collapse").collapse("hide");
        }), a(".noo-navbar-collapse").on("show.bs.collapse", function() {
            a(".noo-user-navbar-collapse").hasClass("in") && a(".noo-user-navbar-collapse").collapse("hide");
            a(".noo-globe-navbar-collapse").hasClass("in") && a(".noo-globe-navbar-collapse").collapse("hide");
        }), d()
    }), a(document).on("noo-layout-changed", function() {
        d()
    })
}(jQuery);

jQuery('document').ready(function ($) {
    $(".form-control-chosen").select2({
        // maximumSelectionLength: 3,
        placeholder: selectmultiMessage
    });

    $(".form-control-chosen-cate").select2({
        maximumSelectionLength: 3,
        placeholder: chon3nganhnghe
    });

    $("#categories").on("change", function (e) {
        if($(this).val() != null){
            if($(this).val().length > 0) $(this).parent().find('.icon-select2').hide();

            if($(this).val().length == 2){
                $('#cate-block').find('.select2-selection__choice').css({'width': '47%'});
            }
            else if($(this).val().length == 3){
                $('#cate-block').find('.select2-selection__choice').css({'width': '31%'});
            }
        }
        else $(this).parent().find('.icon-select2').show();
    });

    $(".form-control-chosen-loca").select2({
        maximumSelectionLength: 3,
        placeholder: chon3diadiem
    });

    $("#locations").on("change", function (e) {
        if($(this).val() != null){
            if($(this).val().length > 0) $(this).parent().find('.icon-select2').hide();

            if($(this).val().length == 2){
                $('#loca-block').find('.select2-selection__choice').css({'width': '47%'});
            }
            else if($(this).val().length == 3){
                $('#loca-block').find('.select2-selection__choice').css({'width': '31%'});
            }
        }
        else $(this).parent().find('.icon-select2').show();
    });
});